import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react";
import { useGetExercisesListQuery } from "../../hooks/useGetDataQuery";
import "./addExercisesDialog.css";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import PatientCodeDialog from "../PatientCodeDialog/patientCodeDialog";
import { Skeleton } from "primereact/skeleton";
import { useOutletContext } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce";

const mappedDays = [
  { name: "Mon", value: "Monday" },
  { name: "Tue", value: "Tuesday" },
  { name: "Wed", value: "Wednesday" },
  { name: "Thu", value: "Thursday" },
  { name: "Fri", value: "Friday" },
  { name: "Sat", value: "Saturday" },
  { name: "Sun", value: "Sunday" }
];

const AddExercisesDialog = ({t, exerciseDialogVisible, setExerciseDialogVisible, setPlan, selectedPatient,setSelectedPatient, codeDialogVisible, setCodeDialogVisible} : any) => {
  const periods = [{ name: t("Morning"), value: "Morning" } , { name: t("Afternoon"), value: "Afternoon" } , { name: t("Evening"), value: "Evening" } ];
  const days = [{ name: t("Mon"), value: "Mon" }, { name: t("Tue"), value: "Tue" }, { name: t("Wed"), value: "Wed" }, { name: t("Thu"), value: "Thu" }, { name: t("Fri"), value: "Fri" }, { name: t("Sat"), value: "Sat" }, { name: t("Sun"), value: "Sun" }];

  const { systemPanels, myPanels } : any = useOutletContext();

  const [lazyParamsForExercises, setLazyParamsForExercises] = useState<any>({
    rows: 100,
    page: 0,
    filters: {
        name: {
            matchMode: "contains",
            value: "",
        },
    },
});
  const { data: exercisesData, isSuccess: isSuccessExercisesData } = useGetExercisesListQuery(lazyParamsForExercises);

  const [exercises, setExercises] = useState<any[]>([]);
  const [tempExercises, setTempExercises] = useState<any[]>([]);
  const [selectedExercises, setSelectedExercises] = useState<any[]>([]);
  const [selectedExercise, setSelectedExercise] = useState<any>();

  const [carePlanName, setCarePlanName] = useState<string>("");
  const [dateOfStart, setDateOfStart] = useState<Date>(new Date());
  const [dateOfEnd, setDateOfEnd] = useState<Date>(() => { return new Date(new Date().setDate(new Date().getDate() + 14)) });

  const [selectedPanel, setSelectedPanel] = useState<any>();

  const [searchExercise, setSearchExercise] = useState<string>("");
  const debouncedValue = useDebounce(searchExercise);

  const purifyData = (data:any) => {
    return data?.map((exercise: any) => {
      return {
        exercise: {
          id: exercise.id,
          name: exercise.name,
          image: exercise.imageUrl,
          videoUrl: exercise.videoUrl
        },
        set: 5,
        repeat: 3,
        tolerance: 15,
        restTime: 10,
        periods: ["Morning"],
        days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      }
    })
  }

  const purifyPanelData = (data:any) => {
    return data?.map((exercise:any) => {
      return {
        exercise: {
          id: exercise.procedureId,
          name: exercise.exerciseName,
          image: exercise.exerciseImg,
          videoUrl: exercise.videoUrl
        },
        set: exercise.set,
        repeat: exercise.repeat,
        tolerance: exercise.tolerance,
        restTime: exercise.restTime,
        periods: exercise.periodSlots,
        days: exercise.days,
      }
    })
  }

  useEffect(() => {
    if(isSuccessExercisesData) {
      const purifiedExercisesData = purifyData(exercisesData?.data)
      setExercises(purifiedExercisesData);
      setTempExercises(purifiedExercisesData);
    }
  }, [exercisesData, isSuccessExercisesData]);

  useEffect(() => {
    if(tempExercises.length === 0) return;

    if(selectedPanel) {
      const filteredExercises = tempExercises.filter((exercise) => exercise.exercise.name.toLowerCase().includes(debouncedValue.toLowerCase()) && selectedPanel.exerciseProcedure.some((procedure:any) => procedure.procedureId === exercise.exercise.id));
      if(filteredExercises.length > 0) {
        setExercises(filteredExercises);
      }
      return;
    }
    
    if(!debouncedValue) {
      setExercises(tempExercises);
      return;
    }

    const filteredExercises = tempExercises.filter((exercise) => exercise.exercise.name.toLowerCase().includes(debouncedValue.toLowerCase()));
    setExercises(filteredExercises);
  }, [debouncedValue]);

  useEffect(() => {
    if (!tempExercises || tempExercises.length === 0) return;

    setSelectedExercise(undefined);

    if (!selectedPanel) {
      setExercises(tempExercises);
      return;
    }
    
    setExercises(purifyPanelData(selectedPanel?.exerciseProcedure));
  }, [selectedPanel]);

  const clearAll = () => {
    setSelectedExercises([]);
    setCarePlanName("");
    setDateOfStart(new Date());
    setDateOfEnd(new Date(new Date().setDate(new Date().getDate() + 14)));
    setSelectedExercise(undefined);
    setSearchExercise("");
  }

  const onHideDialog = () => {
    clearAll();
    setExerciseDialogVisible(false);
  }

  const onCodeDialogHide = () => {
    clearAll();
    localStorage.setItem("patient", JSON.stringify(selectedPatient));
    setCodeDialogVisible(false);
  }

  const handleClick = (exercise : any) => {
    if(selectedExercises.some(selected => selected.exercise.id === exercise.exercise.id)) {
      return;
    }
    if(selectedExercise && selectedExercise.exercise.id === exercise.exercise.id) {
      setSelectedExercise(undefined);
      return;
    }
    setSelectedExercise(exercise);
  }

  const handleDelete = (exercise:any) => {
    const filteredExercises = selectedExercises.filter(item => item !== exercise);
    setSelectedExercises(filteredExercises);
  }

  const handleCarePlanName = ({ target : { value } }:any) => {
    if(value.length <= 50) {
      setCarePlanName(value);
      return;
    }
  }

  const handleApprovePrescription = () => {
    const refactoredExercises = selectedExercises.map((exercise:any) => {
      return {
        exerciseProcedureId: exercise.exercise.id,
        setCount: exercise.set,
        repeatCount: exercise.repeat,
        tolerance: exercise.tolerance,
        restTime: exercise.restTime,
        periods: exercise.periods,
        days: exercise.days.map((day:any) => mappedDays.find(mappedDay => mappedDay.name === day)?.value),
      };
    })

    setPlan({
      name: carePlanName,
      exerciseList: refactoredExercises,
      status: "NotStarted",
      isActive: true,
      patientId: selectedPatient.id,
      planDateStart: new Date(new Date(dateOfStart).getTime() + 3 * 60 * 60 * 1000),
      planDateEnd: new Date(new Date(dateOfEnd).getTime() + 3 * 60 * 60 * 1000),
    })

    onHideDialog();
  }

  const handleDateOfStart = ({value}:any) => {
    setDateOfStart(value);
  }

  const handleDateOfEnd = ({value}:any) => {
    setDateOfEnd(value);
  }

  const handleDeleteAll = () => {
    clearAll();
  }

  const handleAddExercise = () => {
    if(selectedExercise) {
      setSelectedExercises([...selectedExercises, selectedExercise]);
      setSelectedExercise(undefined);
    }
  }

  const handleDetailsChange = (value : number, name : string) => {
    if(!selectedExercise) return;
    if(name === "periods") {
      setSelectedExercise({ ...selectedExercise, [name]: [value] });
    } else {
      setSelectedExercise({ ...selectedExercise, [name]: value });
    }
  }

  const handleSetDefault = () => {
    setSelectedExercise({ ...selectedExercise, set: 5, repeat: 3, tolerance: 15, restTime: 10, periods: ["Morning"], days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"] });
  }

  const handleSearchExercise = ({ target : { value } } : any) => {
    setSearchExercise(value);
  }

  const isDisabledAddExerciseButton = !selectedExercise || !selectedExercise.set || !selectedExercise.repeat || !selectedExercise.tolerance || !selectedExercise.restTime || !selectedExercise.periods || selectedExercise.days.length === 0;

  const skeletonLoader = () => {
    return (
      <>
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            key={index}
            style={{width: "18rem"}}
          >
            <div
              className="flex m-1 pl-2 h-8rem justify-content-between gap-2"
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: "18px",
                boxShadow: "0 4px 4px -1px rgba(45, 57, 103, 0.05), 0 4px 10px -1px rgba(45, 57, 103, 0.1)",
                position: "relative"
              }}
            >
              <div className="flex flex-column justify-content-between gap-2 pt-4 pb-4">
                <Skeleton shape="circle" width="1.5rem" height="1.5rem" />
                <Skeleton width="8rem" height="2rem" className="mb-2" />
              </div>
              <div style={{ width: "55%", position: "relative", zIndex: 2 }}>
                <Skeleton width="100%" height="100%" borderRadius="18px" />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "70%",
                  transform: "translate(-50%, -50%)",
                  width: "50%",
                  height: "100%",
                  zIndex: 1
                }}
              >
                <Skeleton shape="circle" width="100%" height="100%" />
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  const exercisesItemTemplate = (exercise: any) => {
    const includeInSelectedExercises = selectedExercises.some(selected => selected.exercise.id === exercise.exercise.id)
    const isSelected = includeInSelectedExercises || selectedExercise?.exercise.id === exercise.exercise.id;
    const isAdded = includeInSelectedExercises;
    const circleImageSrc = isSelected ? "/exerciseCircleIconOnHover.png" : "/exerciseCircleIcon.png";

    return (
      <div
        key={exercise?.exercise?.id}
        style={{width:"18rem" }}
      >
        <div
          className={`flex m-1 h-8rem pl-2 justify-content-between gap-2 ${isSelected && "clickable-data-div"} ${!isAdded && "cursor-pointer"}`}
          style={{
            backgroundColor: "#F5F5F5",
            borderRadius: "18px",
            position: "relative"
          }}
          onClick={() => { handleClick(exercise) }}
        >
          <div className="flex flex-column justify-content-between pt-3 pb-3">
            <div className="">
              <Button icon={`${isSelected && "pi pi-check"}`} outlined style={{backgroundColor: `${isSelected ? "#23CF95" : "inherit"}`, border: `1.5px solid ${isSelected ? "#23CF95" : "#727A99"}`, color: "#2D3967", borderRadius: "32px", width: "1.65rem", height:"1.5rem"}} />
            </div>
            <p className="text-sm font-bold" style={{ color: "#2D3967" }}>{exercise.exercise.name}</p>
          </div>

          <img src={exercise.exercise.image} style={{ objectFit: "contain", width: "40%", zIndex: 2 }} />

          <img
            src={circleImageSrc}
            style={{
              objectFit: "contain",
              width: "40%",
              position: "absolute",
              top: "50%",
              left: "80%",
              transform: "translate(-50%, -50%)",
              zIndex: 1
            }}
          />
        </div>
      </div>
    );
  }

  const selectedExercisesItemTemplate = (exercise:any) => {
    return (
      <div className="col-12 relative">
        <div className="flex flex-wrap justify-content-between align-items-center bg-white p-3 h-auto min-h-7rem" style={{borderRadius: "18px"}}>
          <Button 
            onClick={() => handleDelete(exercise)} 
            className="absolute w-2rem h-2rem" 
            style={{ backgroundColor: "#E3506B", border: "none", top:"5px", right: "2px"}} 
            icon="pi pi-times" 
            rounded 
            aria-label="Delete" 
          />
          <div className="flex-grow-1 flex-shrink-1 mr-2" style={{ maxWidth: '30%' }}>
            <p className="text-lg font-bold m-0"
               style={{ color: "#2D3967" }}>
              {exercise.exercise.name}
            </p>
          </div>
          <div className="flex gap-2 flex-grow-0 flex-shrink-1">
            <div className="flex flex-column">
              <span className="text-400 font-light text-md">{t("periods")}</span>
              <span className="text-400 font-light text-md">{t("days")}</span>
            </div>
            <div className="flex flex-column min-w-0">
              <span className="font-bold text-overflow-ellipsis overflow-hidden white-space-nowrap" 
                    style={{ color: "#2D3967", maxWidth: "100px" }}>
                {t(exercise.periods)}
              </span>
              <span className="font-bold text-overflow-ellipsis overflow-hidden white-space-nowrap" 
                    style={{ color: "#2D3967", maxWidth: "100px" }}>
                {exercise.days.map((day:any) => t(day)).join(", ")}
              </span>
            </div>
          </div>
          <div className="flex gap-2 flex-grow-0 flex-shrink-1">
            <div className="flex flex-column">
              <span className="text-400 font-light whitespace-nowrap text-md">{t("set")}, {t("reps")}</span>
              <span className="text-400 font-light whitespace-nowrap text-md">Tol, {t("rest")}</span>
            </div>
            <div className="flex flex-column min-w-0">
              <span className="font-bold text-overflow-ellipsis overflow-hidden" 
                    style={{ color: "#2D3967" }}>
                {exercise.set + " x " + exercise.repeat}
              </span>
              <span className="font-bold text-overflow-ellipsis overflow-hidden" 
                    style={{ color: "#2D3967" }}>
                {exercise.tolerance + "%, " + exercise.restTime + " " + t("secs")}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Dialog showHeader={false} className="exercises-dialog" visible={exerciseDialogVisible} blockScroll style={{width: "95vw", height: "100vh", borderRadius: '24px', overflow: "hidden"}} onHide={onHideDialog}>
        <div className="flex h-full" style={{backgroundColor: "#EBEDF2"}}>
          <div className="flex flex-column justify-content-between gap-3 bg-white p-4 w-full" style={{borderRadius: "24px"}}>
            <div className="flex flex-column justify-content-start gap-2">
              <div className="flex flex-wrap justify-content-between align-items-center gap-3">
                <div className="flex flex-grow-1 align-items-center flex-wrap gap-3">
                  <span style={{color: "#727A99"}} className="text-2xl font-medium">{t("selectExercise")}</span>
                  <InputText className="flex-grow-1 h-3rem" value={searchExercise} onChange={handleSearchExercise} id="searchExercise" placeholder={t("searchExercise")} style={{borderRadius: "10px", border: "1px solid #9EA4B9"}} />
                </div>
                <div className="flex flex-grow-1 flex-wrap justify-content-between gap-3">
                  <Dropdown showClear value={selectedPanel} onChange={(e:any) => setSelectedPanel(e.value)} optionLabel="name" placeholder={t("systemPanels")} className="w-12rem flex-grow-1 h-3rem" options={systemPanels} style={{border: "1px solid #2D3967", borderRadius: "10px"}} />
                  <Dropdown showClear value={selectedPanel} onChange={(e:any) => setSelectedPanel(e.value)} optionLabel="name" placeholder={t("myPanels")} className="w-12rem flex-grow-1 h-3rem" options={myPanels} style={{border: "1px solid #2D3967", borderRadius: "10px"}} />
                </div>
              </div>
              <div className="flex flex-wrap justify-content-evenly align-content-start mb-2" style={{height: "20rem", overflowY: "auto", overflowX: "hidden"}}>
                {exercises?.length > 0 ? exercises.map((exercise : any) => exercisesItemTemplate(exercise)) : skeletonLoader()}
              </div>
            </div>
            <span style={{color: "#727A99"}} className="text-2xl font-medium">{t("exerciseDetails")}</span>
            <div className="overflow-y-auto overflow-x-hidden ">
              <div className="grid">
                <div className="col-6">
                  <div className="flex flex-column gap-1">
                    <label htmlFor="periodSlots" style={{color: "#727A99"}}>{t("periodSlots")} <span style={{color: "#E3506B"}}>*</span></label>
                    <Dropdown optionLabel="name" highlightOnSelect={false} checkmark={true} className="add-exercise-dialog-multi-select" disabled={!selectedExercise} value={selectedExercise?.periods[0]} options={periods} onChange={(e:any) => handleDetailsChange(e.value, "periods")} id="periodSlots" style={{borderRadius: "10px", border: "1px solid #9EA4B9"}} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="flex flex-column gap-1">
                    <label htmlFor="days" style={{color: "#727A99"}}>{t("days")} <span style={{color: "#E3506B"}}>*</span></label>
                    <MultiSelect optionLabel="name" className="add-exercise-dialog-multi-select" disabled={!selectedExercise} value={selectedExercise?.days} options={days} display="chip" onChange={(e:any) => handleDetailsChange(e.value, "days")} id="days" style={{borderRadius: "10px", border: "1px solid #9EA4B9"}} />
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col">
                  <div className="flex flex-column gap-1">
                    <label htmlFor="set" style={{color: "#727A99"}}>{t("set")} <span style={{color: "#E3506B"}}>*</span></label>
                    <InputNumber min={0} invalid={selectedExercise && !selectedExercise?.set} className="add-exercise-dialog-input-number" disabled={!selectedExercise} value={selectedExercise?.set} onValueChange={(e:any) => handleDetailsChange(e.value, "set")} inputId="set" placeholder={t("set")} />
                  </div>
                </div>
                <div className="col">
                  <div className="flex flex-column gap-1">
                    <label htmlFor="repeat" style={{color: "#727A99"}}>{t("repeat")} <span style={{color: "#E3506B"}}>*</span></label>
                    <InputNumber min={0} invalid={selectedExercise && !selectedExercise?.repeat} className="add-exercise-dialog-input-number" disabled={!selectedExercise} value={selectedExercise?.repeat} onValueChange={(e:any) => handleDetailsChange(e.value, "repeat")} inputId="repeat" placeholder={t("repeat")} style={{borderRadius: "10px"}} />
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="col">
                  <div className="flex flex-column gap-1">
                    <label htmlFor="tolerance" style={{color: "#727A99"}}>{t("tolerance")} (%) <span style={{color: "#E3506B"}}>*</span></label>
                    <InputNumber min={0} invalid={selectedExercise && !selectedExercise?.tolerance} className="add-exercise-dialog-input-number" disabled={!selectedExercise} value={selectedExercise?.tolerance} max={100} onValueChange={(e:any) => handleDetailsChange(e.value, "tolerance")} inputId="tolerance" placeholder={t("tolerance") + "(%)"} style={{borderRadius: "10px"}} />
                  </div>
                </div>
                <div className="col">
                  <div className="flex flex-column gap-1">
                    <label htmlFor="restTime" style={{color: "#727A99"}}>{t("restTime")} ({t("secs")}) <span style={{color: "#E3506B"}}>*</span></label>
                    <InputNumber min={0} invalid={selectedExercise && !selectedExercise?.restTime} className="add-exercise-dialog-input-number" disabled={!selectedExercise} value={selectedExercise?.restTime} onValueChange={(e:any) => handleDetailsChange(e.value, "restTime")} inputId="restTime" placeholder={t("restTime")} style={{borderRadius: "10px"}} />
                  </div>
                </div>
              </div>
              <div className="flex justify-content-between align-items-center gap-3">
                <Button
                    style={{backgroundColor: "#BEC2D0", border: "none", borderRadius: "10px", height: "3rem"}}
                    onClick={handleSetDefault}
                    disabled={!selectedExercise}
                >
                  <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                    <img src="/set-default.svg" />
                    {t("setDefault")}
                  </span>
                </Button>
                <Button
                    style={{ backgroundColor: "#E3506B", border: "none", borderRadius: "10px", height: "3rem"}}
                    disabled={isDisabledAddExerciseButton}
                    onClick={handleAddExercise}
                    tooltip={t("selectExerciseTooltip")}
                    tooltipOptions={{position: "bottom", showOnDisabled: true, showDelay: 300, disabled: selectedExercise}}
                >
                  <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                    <img src="/Add.svg" />
                    {t("addExercise")}
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-column justify-content-between gap-3 p-4 w-full" style={{backgroundColor: "#EBEDF2"}}>
            <div className="flex flex-column justify-content-start pt-2">
              <div className="flex justify-content-between align-items-center">
                <span className="text-2xl font-bold" style={{color: "#727A99"}}>{t("prescription")} | <span style={{color: "#E3506B"}}>{selectedPatient.firstName + " " + selectedPatient.lastName}</span></span>
                <Button onClick={onHideDialog} className="w-2rem h-2rem" style={{border: "1.5px solid #727A99", color: "#727A99", backgroundColor: "#EBEDF2"}} icon="pi pi-times" outlined rounded aria-label="Delete" />
              </div>
              <div className="" style={{height: "27rem", overflowY: "auto"}}>
                {selectedExercises && selectedExercises.map((exercise : any) => selectedExercisesItemTemplate(exercise))}
              </div>
            </div>
            <div className="overflow-y-auto overflow-x-hidden">
              <div className="flex flex-wrap gap-2">
                <div className="flex w-full flex-column gap-1">
                  <label htmlFor="planName" style={{color: "#727A99"}}>{t("carePlanName")} <span style={{color: "#E3506B"}}>*</span></label>
                  <InputText value={carePlanName} onChange={handleCarePlanName} id="planName" placeholder={t("carePlanName")} style={{borderRadius: "10px", border: "1px solid #9EA4B9"}} />
                </div>
                <div className="flex gap-3 w-full">
                  <div className="flex flex-column gap-1 w-full">
                    <label htmlFor="dateOfStart" style={{color: "#727A99"}}>
                        {t("dateOfStart")} <span style={{color: "#E3506B"}}>*</span>
                    </label>
                    <Calendar className="add-exercise-dialog-calendar" value={dateOfStart} id="dateOfStart" onChange={handleDateOfStart} minDate={new Date()} />
                  </div>
                  <div className="flex flex-column gap-1 w-full">
                    <label htmlFor="dateOfEnd" style={{color: "#727A99"}}>
                        {t("dateOfEnd")} <span>*</span>
                    </label>
                    <Calendar className="add-exercise-dialog-calendar" value={dateOfEnd} id="dateOfEnd" onChange={handleDateOfEnd} minDate={dateOfStart} />
                  </div>
                </div>
                <div className="flex justify-content-between align-items-center gap-3 w-full">
                  <Button
                      style={{backgroundColor: "#BEC2D0", border: "none", borderRadius: "10px", height: "3rem"}}
                      onClick={handleDeleteAll}
                      disabled={selectedExercises.length === 0}
                  >
                    <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                      <img src="/trashIcon.svg" />
                      {t("clearAll")}
                    </span>
                  </Button>
                  <Button
                    style={{backgroundColor: "#E3506B", border: "none", borderRadius: "10px", height: "3rem"}}
                    disabled={selectedExercises.length === 0 || carePlanName === ""}
                    onClick={handleApprovePrescription}
                    tooltip={selectedExercises.length === 0 ? t("addExerciseTooltip") : carePlanName === "" ? t("enterPlanNameTooltip") : ""}
                    tooltipOptions={{position: "bottom", showOnDisabled: true, showDelay: 300}}
                  >
                    <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                      <img src="/Check.svg" />
                      {t("approvePrescription")}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <PatientCodeDialog t={t} codeDialogVisible={codeDialogVisible} selecetedPatient={selectedPatient} carePlanName={carePlanName} selectedExercises={selectedExercises} onCodeDialogHide={onCodeDialogHide} setSelectedPatient={setSelectedPatient}/>
    </>
  )
}

export default AddExercisesDialog;