import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown"
import { useEffect } from "react";

const PatientDetailCard = ({ t, selectedPatient, planList }: any) => {

    const lang = localStorage.getItem("i18nextLng") === "en" ? "en-US" : "tr-TR";

    const birthDateTemplate = (rowData: any) => {
        const date = new Date(rowData.dateCreated)

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const registerDateTemplate = (rowData: any) => {
        const date = new Date(rowData.dateCreated)

        const options: any = { month: 'long', day: 'numeric' };
        return date.toLocaleDateString(lang, options);
    }

    return (
        <div className="w-full h-full">

            <div className="w-full pb-4" style={{ background: "#E0E3EE" }}>
                <div className="flex flex-column justify-content-center align-items-center gap-2">
                    <img src={`${selectedPatient.gender === "Male" ? "/Man.png" : "/Female.png"}`}
                        style={{ borderRadius: "100px", width: "8rem", height: "8rem" }}
                    />
                    <div>
                        <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{selectedPatient.firstName}</p>
                        <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}> {selectedPatient.lastName}</p>
                    </div>
                    <div className='flex flex-row gap-2 '>
                        <img src="/PhoneIcon.svg" />
                        <span className='text-sm' style={{ color: "#727A99" }}>{selectedPatient.phoneNumber ? selectedPatient.phoneNumber : "---"}</span>
                    </div>
                    <div className='flex flex-row  gap-2'>
                        <img src="/gpsIcon.svg" />
                        <span className='text-sm' style={{ color: "#727A99" }}>Ankara, TR</span>
                    </div>

                </div>
            </div>
            <div className="w-full flex flex-column justify-content-between" style={{minHeight:"30vw"}}>
                <div className="flex flex-column justify-content-center px-6 pt-4">
                    <div className="flex justify-content-between align-items-center ">
                        <img src="/birthDateIcon.svg" />
                        <div className="flex flex-column align-items-end">
                            <p className="m-0 text-md" style={{ color: "#727A99" }}>{t("birthDate")}</p>
                            <p className="m-0 text-lg font-bold" style={{ color: "#2D3967" }}>{selectedPatient.birthDate ? birthDateTemplate(selectedPatient) : "---"}</p>
                        </div>

                    </div>
                    <div className="w-full mt-3" style={{ border: "1px dashed #e5e7eb" }}></div>

                    <div className="flex justify-content-between align-items-center mt-3">
                        <img src="/registerIcon.svg" />
                        <div className="flex flex-column align-items-end">
                            <p className="m-0 text-sm" style={{ color: "#727A99", whiteSpace: "nowrap" }}>{t("registerDate")}</p>
                            <p className="m-0 text-md font-bold" style={{ color: "#2D3967", whiteSpace: "nowrap" }}>{selectedPatient.dateCreated ? registerDateTemplate(selectedPatient) : "---"}</p>
                        </div>

                    </div>
                    <div className="w-full mt-3" style={{ border: "1px dashed #e5e7eb" }}></div>

                    <div className="flex justify-content-between align-items-center mt-3">
                        <img src="/weightIcon.svg" />
                        <div className="flex flex-column align-items-end">
                            <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t("weight")}</p>
                            <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{selectedPatient.weight ? selectedPatient.weight : "---"} kg</p>
                        </div>

                    </div>
                    <div className="w-full mt-3" style={{ border: "1px dashed #e5e7eb" }}></div>

                    <div className="flex justify-content-between align-items-center mt-3">
                        <img src="/heightIcon.svg" />
                        <div className="flex flex-column align-items-end">
                            <p className="m-0 text-sm" style={{ color: "#727A99" }}>{t("height")}</p>
                            <p className="m-0 text-md font-bold" style={{ color: "#2D3967" }}>{selectedPatient.height ? selectedPatient.height : "---"} sm</p>
                        </div>

                    </div>
                    <div className="w-full mt-3" style={{ border: "1px dashed #e5e7eb" }}></div>
                </div>

                <div className="flex flex-column justify-content-center align-items-center">
                    <p className="m-0 text-sm" style={{ color: "#727A99" }}>
                        {t("registerCode")} 
                        {(selectedPatient.code) ? (
                            <span className="m-0 text-md font-bold" style={{ color: "#2D3967" }}> {selectedPatient.code}</span>
                        ) : (
                            <span className="m-0 text-md font-bold" style={{ color: "#2D3967" }}> ---</span>
                        )}
                    </p>


                    <Button
                        style={{
                            backgroundColor: "#E3506B",
                            color: "white",
                            border: "#E3506B",
                            height: "2.5rem",
                            borderRadius: "10px",
                            width: "12rem",
                            fontWeight: "bold"
                        }}
                    >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            <img src="/SendIcon.svg" />
                            {t("sendSms")}
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )

}

export default PatientDetailCard