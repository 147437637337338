import axios, { AxiosInstance } from "axios";

const baseURL = process.env.REACT_APP_API_PROD_BASE_URL

const api: AxiosInstance = axios.create({
    baseURL: baseURL,
})

export class Axios {
    public static setAuthToken(token: any) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    public static getAuthToken() {
        return localStorage.getItem("token");
    }
}

export default api;
